import { useEffect, useState } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from 'chess.js'
import './App.css';
import openingsData from './openings.json';
import useSound from 'use-sound'
import pieceSound from './piece.mp3'
import rollbackSound from './rollback.mp3'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'


const Trees = (function() {
  let xs = [];

  function go(dump) {
    let root = null;
    for (const item of dump) {
      if (item.parent == -1) {
        root = item
        root.parent_item = null
      }
      item.children = []
      item.played = false;
    }
    for (const item of dump) {
      if (item.parent != -1) {
        // revisit this
        for (const jt of dump) {
          if (jt.id == item.parent) {
            item.parent_item = jt
            jt.children.push(item);
          }
        }
      }
    }
    return root;
  }

  function count(dump) {
    let n = 0;
    for (const item of dump) {
      n++;
    }
    return n;
  }

  for (const [opening_name, [stp, node_dump]] of Object.entries(openingsData)) {
    xs.push({
      name: opening_name,
      root: go(node_dump),
      stp: stp,
      size: count(node_dump),
    })
  }
  return xs;
})()

function tree_by_name(name) {
  for (const t of Trees) {
    if (name == t.name) {
      return t;
    }
  }
  return null;
}

function count_played(root) {
  if (!root) {
    return 0;
  }
  if (!root.played) {
    return 0;
  }
  let n = 1;
  for (const ch of root.children) {
    n += count_played(ch);
  }
  return n;
}

function traverse(root, f) {
  if (!root) {
    return;
  }
  f(root);
  for (const ch of root.children) {
    traverse(ch, f);
  }
}

function tree_percentage(name) {
  let t = tree_by_name(name);
  if (!t) {
    return 0;
  }
  let n_played = count_played(t.root);
  if (n_played < 2) {
    return 0;
  }
  let perc = 100 * count_played(t.root) / t.size;
  return perc < 1 ? 0 : Math.round(perc);
}

function pick_random(items) {
  return items[Math.floor(Math.random() * items.length)];
}

function has_unplayed(node) {
  if (!node.played) {
    return true;
  }
  for (const ch of node.children) {
    if (has_unplayed(ch)) {
      return true;
    }
  }
  return false;
}

class Playout {
  constructor(root, stp) {
    this.curr = root;
    this.side = stp;
    this.curr.played = true;
  }

  fen() {
    return this.curr.fen;
  }

  stp() {
    return this.side;
  }

  moves() {
    let mvs = [];
    for (const ch of this.curr.children) {
      mvs.push(ch.move);
    }
    return mvs;
  }

  is_legal(from, to, promo) {
    console.log(from, to, promo);
    for (const m of this.moves()) {
      if (m.from == from && m.to == to /*&& m.promotion == promo*/)
        return true;
    }
    return false;
  }

  push(from, to, promo) {
    for (const ch of this.curr.children) {
      if (ch.move.from == from &&
          ch.move.to == to /* &&
          ch.move.promotion == promo */) {
            this.curr = ch;
            return new Playout(ch, this.side);
          }
    }
    return null;
  }

  make_random() {
    let non_played_moves = this.curr.children.filter((ch) => !ch.played);
    if (non_played_moves.length > 0) {
      let ch = pick_random(non_played_moves);
      return new Playout(ch, this.side);
    }

    let has_unplayed_moves = this.curr.children.filter((ch) => has_unplayed(ch));
    if (has_unplayed_moves.length > 0) {
      let ch = pick_random(has_unplayed_moves);
      return new Playout(ch, this.side);
    }

    let ch = pick_random(this.curr.children);
    if (!ch) {
      console.log("no more moves left");
      return null;
    }
    return new Playout(ch, this.side);
  }

  child_count() {
    return this.curr.children.length;
  }
}


const Feedback = {
  missing: '',
  correct: 'Correct',
  wrong: 'Try again',
  hint: ''
}

class OpeningList {
  constructor() {
    this.names = [];
    for (const t of Trees) {
      this.names.push(t.name);
    }
    this.selected = '';
  }
}


export default function App() {
  const [playout, setPlayout] = useState(new Playout(Trees[0].root, Trees[0].stp));
  const [hint, setHint] = useState(new String(''));
  const [percentage, setPercentage] = useState(0);
  const [errNum, setErrNum] = useState(0);
  const [openings, setOpenings] = useState(new OpeningList());
  const [playSound] = useSound(pieceSound);
  const [playRollbackSound] = useSound(rollbackSound);
  const [arrows, setArrows] = useState([]);

  function updatePerc() {
    let perc = tree_percentage(openings.selected);
    console.log(`percentage: ${perc}`);
    setPercentage(perc);
  }

  function handleCompleted() {
    setHint('Completed');
    updatePerc();
  }

  function rollbackToNonPlayed(curr_playout) {
    // curr has the opponent side to move
    let curr = curr_playout.curr;
    if (!curr.parent_item) {
      console.log("!curr_playout.parent_item");
      return null;
    }
    for (let it = curr.parent_item.parent_item; it; it = (it.parent_item ? it.parent_item.parent_item : null)) {
      console.log("iteration");
      let non_played_moves = it.children.filter((ch) => !ch.played);
      if (non_played_moves.length > 0) {
        console.log("non_played_moves.length > 0");
        let ch = pick_random(non_played_moves);
        console.log("picked");
        return [new Playout(it, it.side), new Playout(ch, it.side)];
      }
    }
    return null;
  }

  function make_opp_move(curr_playout) {
    setArrows([]);
    let np = curr_playout.make_random();
    if (np) {
      setPlayout(np, playout.stp());
      playSound();
      let n_ch = np.child_count();
      if (n_ch > 1) {
        setHint(`You have ${n_ch} variations here`);
      }
    } else {
      let arr = rollbackToNonPlayed(curr_playout);
      if (arr) {
        let [prev, np] = arr;
        updatePerc();
        setPlayout(prev, playout.stp());
        playRollbackSound();
        setTimeout(() => {
          setPlayout(np, playout.stp());
          playSound();
        }, 800);
      } else {
        handleCompleted()
      }
    }
  }

  function move_to_arrows(m) {
      return [[m.from, m.to]];
  }

  function handleWrongMove() {
    let moves = playout.moves();
    setErrNum(errNum + 1);
    if (errNum >= 0 && moves.length > 0) {
      let hint = moves.map((m) => m.uci).join(" ");
      setHint(hint);
      setArrows(move_to_arrows(moves[0]));
    } else if (moves.length == 0) {
      handleCompleted();
    } else {
      setHint('Try again');
    }
    let str_mvs = JSON.stringify(moves);
    console.log(`expected: ${str_mvs}`);
  }

  function handleRightMove(new_playout) {
    setHint(`Correct!`);
    setErrNum(0);
    playSound();
    setPlayout(new_playout);
    setTimeout(() => { make_opp_move(new_playout); }, 250);
  }

  function onDrop(from, to, promo) {
    if (!playout.is_legal(from, to, promo)) {
      handleWrongMove();
      return false;
    }
    let new_playout = playout.push(from, to, promo);
    if (!new_playout) {
      console.error("unable to push a legal move");
    }
    handleRightMove(new_playout);
    return true;
  }

  function setPlayoutFromSelected() {
    for (const t of Trees) {
      if (openings.selected == t.name) {
        traverse(t.root, function(node) { node.played = false; } );
        let np = new Playout(t.root, t.stp);
        setPlayout(np);
        setPercentage(tree_percentage(openings.selected))
        setTimeout(() => {
          if (t.stp == "black") {
            make_opp_move(np);
          }
          setHint('Start playing');
        }, 500);
      }
    }
  }

  function on_new_opening(e) {
    if (e.value == openings.selected) {
      return;
    }
    openings.selected = e.value;
    setPlayoutFromSelected();
  }

  function onRestart() {
    setArrows([]);
    setPlayoutFromSelected();
  }

  return <>
    <div className="title">Chess Opening Trainer</div>
    <div className="flex-cont">
      <div className="left-side"></div>
      <div className="board-cont">
        <Chessboard position={playout.fen()}
                    onPieceDrop={onDrop}
                    boardOrientation={playout.stp()}
                    customArrows={arrows}
                    animationDuration="150" />
      </div>
      <div className="status-cont">
        <Dropdown
          options={openings.names}
          onChange={on_new_opening}
          value={openings.selected}
          placeholder="Select an opening to train"
          className="select-opening"
        />
        <div className="percentage">{percentage} %</div>
        <div className="button-cont">
          <button id='start-button' onClick={onRestart}>Restart</button>
        </div>
        <div className="hint">{hint}</div>
      </div>
    </div>
    <div className="fen">{playout.fen()}</div>
  </>
}
